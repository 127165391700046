.explore-products-container {
  padding: 70px 50px;
  color: #fff;
  background-color: var(--s);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
}
.popular-games {
  width: 100%;
  padding: 20px;
  background-color: var(--p);
  border-radius: 15px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}
.titlee {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titlee h5 {
  text-transform: uppercase;
  font-weight: 800;
}
.titlee span {
  cursor: pointer;
  text-decoration: underline;
}
.games-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 30px;
}
.game {
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
  padding: 10px;
  border-radius: 15px;
  background-color: var(--p);
  border: 1px solid rgba(255, 255, 255, 0.4);
  &:hover {
    .game-img {
      scale: 1.03;
    }
  }
}
.game img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
}
.game h5 {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
}
.game .buy-now {
  background-color: var(--t);
  padding: 10px;
  border: transparent;
  outline: transparent;
  font-size: 12px;
  border-radius: 15px;
  margin-top: 10px;
}

@media screen and (max-width: 992px) {
  .explore-products-container {
    padding: 50px 20px;
    flex-wrap: wrap;
  }
  .popular-games {
    width: 100%;
  }
  .games-list .game {
    width: 20%;
  }
}
@media screen and (max-width: 600px) {
  .explore-products-container {
    padding: 30px 10px;
  }
  .popular-games {
    padding: 30px 10px;
  }
  .popular-games h5,
  .popular-games span {
    font-size: 15px;
  }
  .games-list {
    gap: 10px;
    justify-content: space-between;
  }
  .games-list .game {
    width: 30%;
  }
  .game img {
    width: 100%;
    height: 100px;
  }
  .game h5 {
    font-size: 10px;
  }
  .game .buy-now {
    font-size: 10px;
  }
}
@media screen and (max-width: 400px) {
  .popular-games {
    width: 100%;
  }
  .popular-games h5,
  .popular-games span {
    font-size: 15px;
  }
  .games-list {
    gap: 20px;
  }
  .games-list .game {
    width: 45%;
  }
}
